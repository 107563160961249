import {Form, Link, useLocation} from '@remix-run/react';
import {Bell, Facebook, Home, LucideLogOut, Menu, Moon, Newspaper, Sun} from 'lucide-react';
import {useRoot} from '~/domain/theme';
import {Button} from '~/ui/button';
import {Popover, PopoverContent, PopoverTrigger} from '~/ui/popover';
import {cn} from '~/utils';
import {OpenedLogo} from './OpenedLogo';

export const Header = () => {
  const {theme, toggle, isAuthed} = useRoot();
  const {pathname} = useLocation();
  const tab = pathname.split('/')[1];
  const isBlog = tab === 'blog';
  const isApp = tab === 'app';
  return (
    <header
      className={cn(
        'fixed flex flex-row top-0 left-0 right-0 p-4 gap-4 items-center h-16',
        'backdrop-blur-lg z-30 bg-accent/15',
      )}>
      <Link to="/" className="items-center flex flex-row gap-4">
        <OpenedLogo className="h-8 aspect-square" />
        <h4 className="grow text-2xl font-bold sm:inline hidden">Mon Pont Chaban</h4>
      </Link>
      <div className="flex-1" />
      <Popover>
        <PopoverTrigger asChild className="sm:hidden flex">
          <Button variant="ghost" size="icon">
            <Menu className="size-6" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="mr-8">
          <div className="flex flex-col gap-4">
            <Button asChild variant="ghost" className="justify-start">
              <Link to="/">
                <Home className="size-4 mr-4" />
                Accueil
              </Link>
            </Button>
            {isAuthed && (
              <Button asChild variant={isApp ? 'default' : 'ghost'} className="justify-start">
                <Link to="/app">
                  <Bell className="size-4 mr-4" />
                  Mes notifications
                </Link>
              </Button>
            )}
            <Button asChild variant={isBlog ? 'default' : 'ghost'} className="justify-start">
              <Link to="/blog">
                <Newspaper className="size-4 mr-4" />
                Blog
              </Link>
            </Button>

            <Button className="justify-start" asChild variant="ghost">
              <Link aria-label="Facebook" to="https://www.facebook.com/monpontchaban">
                <Facebook className="size-4 mr-4" />
                Suivez nous sur Facebook
              </Link>
            </Button>

            <Button className="justify-start" variant="ghost" aria-label="Modifier le thème" onClick={toggle}>
              {theme === 'dark' ? <Moon className="size-4 mr-4" /> : <Sun className="size-4 mr-4" />}
              Changez le thème
            </Button>
            {isAuthed && <LogoutButtonSm />}
          </div>
        </PopoverContent>
      </Popover>
      <div className="hidden sm:flex gap-4 flex-row items-center">
        <Button asChild variant={isBlog ? 'default' : 'ghost'}>
          <Link to="/blog">
            <Newspaper className="size-6 mr-2" />
            Blog
          </Link>
        </Button>
        {isAuthed && (
          <Button asChild variant={isApp ? 'default' : 'ghost'}>
            <Link to="/app">
              <Bell className="size-6 mr-2" />
              Mes notifications
            </Link>
          </Button>
        )}
        <Button asChild variant="ghost" size="icon">
          <Link aria-label="Facebook" to="https://www.facebook.com/monpontchaban">
            <Facebook className="size-6" />
          </Link>
        </Button>

        <Button variant="ghost" size="icon" aria-label="Modifier le thème" onClick={toggle}>
          {theme === 'dark' ? <Moon className="size-6" /> : <Sun className="size-6" />}
        </Button>
        {isAuthed && <LogoutButtonLg />}
      </div>
    </header>
  );
};

export const LogoutButtonLg = () => {
  return (
    <Form action="/auth/logout" method="POST">
      <Button type="submit" variant="ghost" size="icon">
        <LucideLogOut className="size-6" />
      </Button>
    </Form>
  );
};

export const LogoutButtonSm = () => {
  return (
    <Form action="/auth/logout" method="POST">
      <Button className="justify-start w-full" variant="ghost" type="submit">
        <LucideLogOut className="size-6 mr-2" />
        Se déconnecter
      </Button>
    </Form>
  );
};
